import React, { useContext, useEffect, useState } from "react";
import useStyles from "./style";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { getAllBrandsRequest } from "../../../api/brand.api";
import useCancellablePromise from "../../../api/cancelRequest";
import no_image_found from "../../../assets/images/no_image_found.png";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as PreviousIcon } from "../../../assets/images/previous.svg";
import { ReactComponent as NextIcon } from "../../../assets/images/next.svg";
import { getValueFromCookie } from "../../../utils/cookies";
import Loading from "../../shared/loading/loading";
import InputBase from "@mui/material/InputBase";


const BrandCard = ({ data, index, onMouseOver }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, descriptor, address } = data;
  const { name: brand_name, symbol } = descriptor;

  // if(symbol) {
  //   const target = symbol.indexOf('null');
  //   if(target > 0) {
  //     symbol = no_image_found
  //   }
  //   console.log('null string index', target)
  // }


  return (
    <>
      {/* <Tooltip title={brand_name}>
        <Card
          className={classes.brandCard}
          onMouseOver={onMouseOver}
          onClick={() => history.push(`/application/brand?brandId=${id}`)}
        >
          <img className={classes.brandImage} src={symbol ? symbol : no_image_found} alt={`brand-${index}`} />
        </Card>
      </Tooltip> */}

      <div className='col-12 col-md-4 col-lg-3'>
        <div className='store_grid' onClick={() => history.push(`/application/brand?brandId=${id}`)}>
          <div className='topBrand_img' onMouseOver={onMouseOver} onClick={() => history.push(`/application/brand?brandId=${id}`)}>
            {/* <img src={data.imageUrl ? data.imageUrl : no_image_found} alt={`sub-cat-img-${data.value}`} /> */}
            <img className="" src={symbol ? symbol : no_image_found} alt={`brand-${index}`} />
          </div>
          <div className='topBrandInfo'>
            <p>{brand_name || ""}</p>
            <h6 className='' ><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> {address?.locality}, {address?.city}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

const TopBrands = () => {
  const classes = useStyles();
  const [activeBrandIndex, setActiveBrandIndex] = useState(1);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isViewAllStore, setisViewAllStore] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(12);
  const dispatch = useContext(ToastContext);
  const [searchValue, setSearchValue] = useState('');
  function handleResize() {
    const width = window.innerWidth;
    setScreenWidth(width);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const getAllBrands = async (searchName) => {
    setIsLoading(true);
    try {

      let search_con = getValueFromCookie("search_context");
      let reqParams = {
        lat: "28.718405",
        lng: "77.109186",
      };
      if (search_con) {
        let sc = JSON.parse(getValueFromCookie("search_context") || {});
        reqParams = {
          lat: sc?.location?.lat || "28.718405",
          lng: sc?.location?.lng || "77.109186",
        };
      }

      const data = await cancellablePromise(getAllBrandsRequest(reqParams));
      setBrands(data.data);
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };
  const ViewAllStore = async (Isviweall) => {
    setisViewAllStore(Isviweall);
    if (Isviweall) {
      setrowsPerPage(brands?.length || 50)
    } else {
      setrowsPerPage(12)
    }
  }

  const handleSearch = async () => {
    console.log('Search value:', searchValue);
    let reqParams = {
      search: searchValue,
    };
    const data = await cancellablePromise(getAllBrandsRequest(reqParams));
    setBrands(data.data);
    // Add the functionality you want to trigger on Enter key press
  };


  useEffect(() => {
    console.log('useEffect')
    getAllBrands();
  }, []);

  // let rowsPerPage = 12;
  const totalPageCount = Math.ceil(brands.length / rowsPerPage);
  return (
    <div className="container">
      <div className='row mt-5'>
        <div className='col-12 col-md-8 col-lg-8'>
          <Typography className="" variant="h4">Partner Stores</Typography>
        </div>
        <div className='col-12 col-md-4 col-lg-4'>
          <InputBase
            fullWidth
            className="provider_search"
            placeholder="Search provider..."
            inputProps={{ "aria-label": "Search..." }}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSearch();
              }
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.brandsContainer}>
          <Loading />
        </Grid>
      ) : (
        <>
          {/* {brands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((brand, brandIndex) => ( */}
          <div className='row'>
            {brands?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((brand, brandIndex) => (
              <BrandCard
                key={`btand-index-${brandIndex}`}
                data={brand}
                index={brandIndex}
                // isActive={brandIndex === activeBrandIndex}
                onMouseOver={() => {
                  setActiveBrandIndex(brandIndex);
                }}
              />
            ))}
          </div>
          {brands?.length > 12 && (
            <>
              <div className='row text-center' >
                <div className='mt-3 mb-3' item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      ViewAllStore(!isViewAllStore);
                    }}
                  >
                    {isViewAllStore ? "View Less" : "View All"}
                  </Button>
                </div>
              </div>
            </>
          )}

          {brands?.length == 0 && (
            <>
              <div className='row'>
                <span>No provider found ...</span>
              </div>
            </>
          )}

        </>
      )}
    </div>
  );
};
export default TopBrands;

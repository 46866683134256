import React, { useState, useEffect, useContext } from 'react';
import useStyles from './style';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from "react-router-dom";
import { getCall, postCall } from "../../api/axios";

import Agriculture from '../../assets/images/category/agriculturenew.jpg';
import AgricultureFarmer from '../../assets/images/banner-img.png';
import AboutLogo from '../../assets/images/about-logo-website.png';
import Reach from '../../assets/images/reach.png';
import Demonstrate from '../../assets/images/Demonstrate.png';
import Transact from '../../assets/images/Transact.png';
import Interact from '../../assets/images/Interact.png';
import Impact from '../../assets/images/Impact.png';
import ParnerImg from '../../assets/images/hesa_partner.jpg';
import OndcNetwork from '../../assets/images/ONDC-Network.png';
import SubCategories from "../category/subCategories/subCategories";
import HomesubCategories from "../category/subCategories/HomesubCategory";
import TopBrands from "./topBrands/topBrands";
import ProductList from "../product/productList/productList";
import { ToastContext } from "../../context/toastContext";
import { toast_actions, toast_types } from "../shared/toast/utils/toast";
import ProductGridView from "../product/productList/productGridView";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {
    getAllProductRequest,
    getAllFiltersRequest,
    getAllFilterValuesRequest,
} from "../../api/product.api";
import useCancellablePromise from "../../api/cancelRequest";
import Loading from "../shared/loading/loading";
import { CartContext } from "../../context/cartContext";
import { getValueFromCookie } from "../../utils/cookies";


const Home = () => {
    const history = useHistory();
    const classes = useStyles();
    const locationData = useLocation();
    const [products, setProducts] = useState([]);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 1,
        pageSize: 8,
        searchData: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [productLoading, setProductLoading] = useState(false);
    const [productPayload, setProductPayload] = useState(null);
    const useQuery = () => {
        const { search } = locationData;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const subCategoryName = query.get("sc") || '';
    const dispatch = useContext(ToastContext);
    


    const [isViewAllCategories, setIsViewAllCategories] = useState(false);
    const { fetchCartItems, cartItems } = useContext(CartContext);

    const updateQueryParams = (catName) => {
        const params = new URLSearchParams({});
        params.set("c", catName);
        if (locationData.search === "" && query.get("c") === null) {
            history.push({ pathname: locationData.pathname, search: params.toString() })
        } else {

        }

    };

    const getAllProducts = async (searchName) => {
        setIsLoading(true);
        try {
            let paginationData = Object.assign(
                {},
                JSON.parse(JSON.stringify(paginationModel))
            );
            paginationData.searchData = paginationData.searchData.filter(
                (item) => item.selectedValues.length > 0
            );
            paginationData.searchData = paginationData.searchData.reduce(function (
                r,
                e
            ) {
                r[e.code] = e.selectedValues.join();
                return r;
            },
                {});
            paginationData.searchData.pageNumber = paginationData.page;
            paginationData.searchData.limit = paginationData.pageSize;
            // if (searchName) {
            //     paginationData.searchData.name = searchName || "";
            // } else {
            // }
            // if (subCategoryName) {
            //     paginationData.searchData.categoryIds = subCategoryName || "";
            // } else {
            // }
            // paginationData.searchData.providerIds = "preprod-nestle-bpp.shopalyst.com_ONDC:RET10_2cce1192aa42f5d1178226bc08cb5f45";
            paginationData.searchData.providerIds = "preprod-ondc.addble.com_ONDC:RET10_H6JplBH0HQxkVoKWAnD3";
            const data = await cancellablePromise(
                getAllProductRequest(paginationData.searchData)
            );
            setProducts(data.data);
            setTotalProductCount(data.count);
        } catch (err) {
            dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                    id: Math.floor(Math.random() * 100),
                    type: toast_types.error,
                    message: err?.response?.data?.error?.message,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (locationData) {
            const searchName = "paddy";
            getAllProducts(searchName);
        }
    }, [paginationModel]);

    const getProductDetails = async (productId) => {
        try {
            setProductLoading(true);
            const encodedId = encodeURIComponent(productId);
            const data = await cancellablePromise(
                getCall(`/protocol/item-details?id=${encodedId}`)
            );
            setProductPayload(data);
            return data;
        } catch (error) {
            console.error("Error fetching product details:", error);
        } finally {
            setProductLoading(false);
        }
    };
    const handleAddToCart = async (
        productPayload,
        isDefault = false,
        navigate
      ) => {
        const user = JSON.parse(getValueFromCookie("user"));
        const url = `/clientApis/v2/cart/${user.id}`;
    
        const subtotal = productPayload.item_details.price.value;
        const payload = {
          id: productPayload.id,
          local_id: productPayload.local_id,
          bpp_id: productPayload.bpp_details.bpp_id,
          bpp_uri: productPayload.context.bpp_uri,
          domain: productPayload.context.domain,
          quantity: {
            count: 1,
          },
          provider: {
            id: productPayload.bpp_details.bpp_id,
            locations: productPayload.locations,
            ...productPayload.provider_details,
          },
          product: {
            id: productPayload.id,
            subtotal,
            ...productPayload.item_details,
          },
          customisations: [],
          hasCustomisations:
            productPayload.hasOwnProperty("customisation_groups") &&
            productPayload.customisation_groups.length > 0,
        };
    
        const res = await postCall(url, payload);
        if (navigate) {
          history.push("/application/cart");
        }
        fetchCartItems();
      };

    return (
        <>
            <Grid container>
                <Grid xs={12}>
                    <div className="homebanner">
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-7'>
                                    <div className="bannerDescription">
                                        <img className='ondc_n_logo' src={OndcNetwork} alt="" />
                                        <h5>Empowering Farmers <span>HESA</span></h5>
                                        <p>Your Source for Premium Agri Inputs and Sustainable Farming Solutions</p>
                                        <Button className="buy_now_btn">Buy Now</Button>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='farmer_pic'>
                                        <img className='' src={AgricultureFarmer} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <HomesubCategories />
            <Box className='container'>
                {products.length > 0 ?
                    <Grid className='mb-4' item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4">
                            Products
                        </Typography>
                    </Grid>
                    : ''}

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container spacing={4}>
                        <Row className="mt-4">
                            {isLoading ? (
                                <Col item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {/* <Loading /> */}
                                </Col>
                            ) : (
                                <>
                                    {products.length > 0 ? (
                                        <>
                                            {products.map((productItem, ind) => {

                                                return (
                                                    <Col
                                                        key={`product-item-${ind}`}
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={4}
                                                        lg={3}
                                                        xl={3}
                                                        className='mb-4'
                                                    >
                                                        <ProductGridView
                                                            product={productItem?.item_details}
                                                            productId={productItem.id}
                                                            price={productItem?.item_details?.price}
                                                            bpp_provider_descriptor={
                                                                productItem?.provider_details?.descriptor
                                                            }
                                                            bpp_id={productItem?.bpp_details?.bpp_id}
                                                            location_id={
                                                                productItem?.location_details
                                                                    ? productItem.location_details?.id
                                                                    : ""
                                                            }
                                                            bpp_provider_id={productItem?.provider_details?.id}
                                                            getProductDetails={getProductDetails}
                                                            handleAddToCart={handleAddToCart}
                                                            cartItems={cartItems}
                                                        // productLoading={productLoading}
                                                        />
                                                    </Col>
                                                );

                                            })}
                                        </>
                                    ) : (
                                        <Col item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="body1">No Products available</Typography>
                                        </Col>
                                    )}
                                </>
                            )}
                        </Row>
                    </Container>
                </Grid>
            </Box>

            <Box className='container'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'none' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card className={classes.agricultureCategory} onClick={() => updateQueryParams(`Agriculture`)}>
                                <div className={classes.agricultureImages}>
                                    <img className={classes.agricultureImage} src={Agriculture} alt="Agriculture 1" />
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ display: 'none' }}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.viewAllLessButtonContainer}>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                setIsViewAllCategories(!isViewAllCategories);
                            }}
                        >
                            {isViewAllCategories ? "View Less" : "View All"}
                        </Button>
                    </Grid>
                </Grid>

                <TopBrands />
            </Box>

            <div className='container'>
                <div className='about_descr'>
                    <div className='row'>
                        <div className='offset-lg-1 col-lg-10 text-center'>
                            <img className='abt' src={AboutLogo} alt="" />
                            <h3 className='mb-3'>Re-defining rural commerce. </h3>
                            <p>Hesa is the one-stop-solution for Corporates, SMEs, Banks, Government and NGOs who wish to access rural India, for buy-sell propositions including brand promotions and social responsibilities.</p>
                            <p>A trusted B2B digital platform backed by the strength and power of a large ground force called the Hesaathis, this powerful combination allows businesses the means to access and integrate into the rural market.</p>
                        </div>
                    </div>

                    <div className='int_reach'>
                        <div className='row justify-content-center'>
                        <div className='col-6 col-sm-6 col-md-3'>
                                <div className='info text-center'>
                                    <img className='' src={Reach} alt="" />
                                    <h5>Reach</h5>
                                </div>
                            </div>

                            <div className='col-6 col-sm-6 col-md-3'>
                                <div className='info text-center'>
                                    <img className='' src={Demonstrate} alt="" />
                                    <h5>Demonstrate</h5>
                                </div>
                            </div>

                            <div className='col-6 col-sm-6 col-md-3'>
                                <div className='info text-center'>
                                    <img className='' src={Transact} alt="" />
                                    <h5>Transact</h5>
                                </div>
                            </div>

                            <div className='col-6 col-sm-6 col-md-3'>
                                <div className='info text-center'>
                                    <img className='' src={Interact} alt="" />
                                    <h5>Interact</h5>
                                </div>
                            </div>

                            <div className='col-6 col-sm-6 col-md-3'>
                                <div className='info text-center'>
                                    <img className='' src={Impact} alt="" />
                                    <h5>Impact</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <Box className='container mt-5 mb-5'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <div className='agr_banner_btm'>
                            <div className='banr_info'>
                                <p>Your Trusted Partner in Quality Agri Inputs</p>
                                <h3>Start Selling on ONDC Network</h3>
                                <Button className='shop_buy_btn mt-3'>Sell on ONDC Network</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <div className='hesa_partner'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='part_info'>
                                <h3>Our Valued Partners</h3>
                                <p>Rural commerce ecosystem is still driven by trust, relationships and physical interactions. But without a digital interface, the potential is largely untapped. We are changing that by offering every business access to its targeted rural population through our three-tiered social, digital and physical platform.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='h_partnr_logo'><img className='' src={ParnerImg} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default Home;
import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";

import no_image_found from "../../../assets/images/no_image_found.png";

const ProductListView = (props) => {
  const [itemAvailableInCart, setItemAvailableInCart] = useState(null);

  const classes = useStyles();
  const history = useHistory();
  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => { },
    handleAddToCart = () => { },
    getProductDetails,
    productLoading,
    cartItems
  } = props;
  const { id, descriptor, provider_details } = product;
  const { name: provider_name } = bpp_provider_descriptor;
  const { name: product_name, images, short_desc: product_description, symbol } = descriptor;

  useEffect(() => {
    if (
      cartItems &&
      cartItems.length > 0
    ) {
      let findItem = null;
      findItem = cartItems.find((item) => item.item.id === productId);
      if (findItem) {
        // isItemAvailable = true;
        setItemAvailableInCart(findItem);
      } else {
      }
    }
  }, [cartItems]);

  return (
    <Grid container spacing={0} className={classes.productItemContainerList}>
      <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
        <Card className={classes.productCardList}>
          <img className={classes.productImage} src={symbol ? symbol : no_image_found} alt={`sub-cat-img-${bpp_id}`} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={9.5} lg={9.5} xl={9.5} className={classes.productDetailsTypo}>
        <Typography component="div" variant="h5" className={classes.productNameTypoList}>
          {product_name} ...
        </Typography>
        <Typography variant="body1" className={classes.providerTypoList}>
          {provider_name}
        </Typography>
        <Typography variant="h4" className={classes.priceTypoList}>
          {`₹${Number.isInteger(Number(price?.value)) ? Number(price?.value).toFixed(2) : Number(price?.value).toFixed(2)
            }`}
        </Typography>
        <Typography component="div" variant="body" className={classes.descriptionTypoList}>
          {product_description}
        </Typography>
        <div className={classes.footerActions}>
          <MuiLink component={Link} to={`/application/products?productId=${encodeURIComponent(productId)}`}>
            View details
          </MuiLink>

          {/* <Button
            className={classes.addToCartBtn}
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              getProductDetails(productId).then((data) => handleAddToCart(data, true, true));
            }}
          >
            Buy Now
          </Button> */}
          {
            !itemAvailableInCart ? (
              <Button
                className={classes.addToCartBtn}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  getProductDetails(productId).then((data) => handleAddToCart(data, true));
                }}
              >
                Add to cart
              </Button>
            ) : <Button
              className={classes.addToCartBtn}
              variant="outlined"
              onClick={() => history.push(`/application/cart`)}
            >
              View Cart
            </Button>}
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductListView;
